import React, { useState, useEffect } from "react"
import { Container, Table } from "react-bootstrap"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../components/Layout/Layout"
import TableFilters from "../../components/TableFilters/TableFilters"
import SEO from "../../components/seo"

import * as formatFunctions from "../../utils/formatProgramTable"

const ProgramDB = props => {
  const initialProgramList = props.data.allContentfulProgram.nodes || []
  const initialState = JSON.parse(
    ( typeof window !== 'undefined' 
      && window.localStorage.getItem("gridFabricProgramsDbState") ) || 
    "{}"
  )

  const [sortBy, changeSortBy] = useState(initialState.sortBy || "programName")
  const [sortAscending, changeSortOrder] = useState(
    initialState.sortAscending || true
  )

  const [selectedFilters, updateSelectedFilters] = useState(
    initialState.filters || formatFunctions.createFilters(initialProgramList)
  )

  const [programList, setProgramList] = useState([])

  useEffect(() => {
    setProgramList(
      formatFunctions.filterProgramList(
        initialProgramList,
        selectedFilters,
        sortBy,
        sortAscending
      )
    )
    window.localStorage.setItem(
      "gridFabricProgramsDbState",
      JSON.stringify({
        filters: selectedFilters,
        sortBy,
        sortAscending,
      })
    )
  }, [initialProgramList, sortBy, sortAscending, selectedFilters])

  function updateSort(sortField) {
    if (sortBy === sortField) {
      changeSortOrder(!sortAscending)
    } else {
      changeSortOrder(true)
      changeSortBy(sortField)
    }
  }

  function updateFilter(field, item) {
    const toggledValue = !selectedFilters[field][item]
    updateSelectedFilters({
      ...selectedFilters,
      [field]: {
        ...selectedFilters[field],
        [item]: toggledValue,
      },
    })
  }

  function selectOnly(field, item) {
    updateSelectedFilters({
      ...selectedFilters,
      [field]: formatFunctions.selectOnlyOne(selectedFilters[field], item),
    })
  }

  function selectAll(field) {
    updateSelectedFilters({
      ...selectedFilters,
      [field]: formatFunctions.selectAll(selectedFilters[field]),
    })
  }

  return (
    <Layout>
      <SEO title="OpenADR Program Database" />
      <div>
        <div className="page-header">
          <Container>
            <h1 style={{ padding: "40px" }}>OpenADR load shifting programs</h1>
          </Container>
        </div>
      </div>
      <div className="divider-image">
        <Img fixed={props.data.icon.image.fixed} alt="" />
      </div>
      <div style={{ padding: "0 15px", minHeight: "700px" }}>
        <div style={{ width: "100%", textAlign: "center" }}>
          <h4>OpenADR Programs</h4>
          <Link
            to="/oadr-programs/about"
            style={{
              fontSize: "1em",
              fontWeight: "normal",
            }}
          >
            about this list
          </Link>
        </div>

        <TableFilters
          selectedFilters={selectedFilters}
          updateFilter={updateFilter}
          selectOnly={selectOnly}
          selectAll={selectAll}
        />
        <Table
          hover
          responsive="lg"
          style={{ maxWidth: "1300px", margin: "auto" }}
          className="table-feature"
        >
          <thead>
            <tr>
              <th
                onClick={() => updateSort("programName")}
                style={{ minWidth: "170px" }}
                className="table-header"
              >
                <span className="inlineClickable" style={{ color: "#777" }}>
                  Program Name{" "}
                  <formatFunctions.SortIndicator
                    field="programName"
                    sortBy={sortBy}
                    sortAscending={sortAscending}
                  />
                </span>
              </th>
              <th
                onClick={() => updateSort("utilityAdministrator")}
                style={{ minWidth: "85px" }}
                className="table-header"
              >
                <span className="inlineClickable" style={{ color: "#777" }}>
                  Utility{" "}
                  <formatFunctions.SortIndicator
                    field="utilityAdministrator"
                    sortBy={sortBy}
                    sortAscending={sortAscending}
                  />
                </span>
              </th>
              <th className="table-header">Location</th>
              <th className="table-header">Sector</th>
              <th className="table-header" style={{ minWidth: "250px" }}>
                Devices Targeted
              </th>
              <th className="table-header" style={{ minWidth: "250px" }}>
                Incentives
              </th>
            </tr>
          </thead>
          <tbody>{programList.map(formatFunctions.formatEntry)}</tbody>
        </Table>
      </div>
    </Layout>
  )
}

export default ProgramDB

export const programQuery = graphql`
  query programQuery {
    allContentfulProgram {
      nodes {
        description {
          description
        }
        deviceTypes
        contentful_id
        incentiveTypes
        incentives {
          incentives
        }
        link
        locations
        openAdrRequirement
        programName
        sectors
        utilityAdministrator
        equipmentRebates {
          equipmentRebates
        }
        slug
      }
    }
    icon: contentfulWebsiteImages(imageId: { eq: "gridfabricMiniIcon" }) {
      image {
        fixed(width: 30) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
    }
  }
`

import React from "react"
import { navigate } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons"

export function formatList(list) {
  if (!list || !list.length) return <div></div>

  return list.map((text, index) => <p style={{ marginBottom: "0em" }} key={index}>{text}</p>)
}

export function formatULList(list) {
    if (!list || !list.length) return <div></div>

    if (list.length === 1) {
      return <p>{list[0]}</p>
    }

    return <ul>{list.map((text, index) => <li style={{ marginBottom: "0em" }} key={index}>{text}</li>)}</ul>
}

export function formatIncentivesList(incentives, equipmentRebates) {
  return (
    <div>
      {incentives && (
        <div>
          <h6 style={{ fontWeight: "800" }}>
            Incentives
          </h6>
          <div style={{ paddingLeft: "0px" }}>

            {formatULList(incentives.incentives.split("\n"))}{" "}

          </div>
        </div>
      )}
      {equipmentRebates && (
        <div>
          <h6 style={{ fontWeight: "800" }}>
            Equipment Rebates
          </h6>
          <div style={{ paddingLeft: "0px" }}>

            {formatULList(equipmentRebates.equipmentRebates.split("\n"))}

          </div>
        </div>
      )}
    </div>
  )
}

export function formatEntry(program, index) {
  return (
    <tr key={index} className="clickable" onClick={event => {
      event.preventDefault()
      navigate(`/oadr-programs/${program.slug}`)
    }}>
      <td className="text-gridfabric">
        {program.programName}
      </td>
      <td>{program.utilityAdministrator}</td>
      <td>{formatList(program.locations)}</td>
      <td>{formatList(program.sectors)}</td>
      <td>{formatList(program.deviceTypes)}</td>
      <td>
        {formatIncentivesList(program.incentives, program.equipmentRebates)}
      </td>
    </tr>
  )
}

export function createFilters(programList){
  return {
    deviceTypes: createFilterState(programList.reduce(createUniqueSet("deviceTypes"), new Set())),
    locations: createFilterState(programList.reduce(createUniqueSet("locations"), new Set())),
    sectors: createFilterState(programList.reduce(createUniqueSet("sectors"), new Set())),
    incentiveTypes: createFilterState(programList.reduce(createUniqueSet("incentiveTypes"), new Set())),
  }
}

function createUniqueSet( param ){
  return (set, currentValue) => {
    return currentValue[param].reduce((newSet, currentVal) => newSet.add(currentVal), set)
  }
}

function createFilterState(filterList){
  let filterState = {}
  Array.from(filterList).forEach(item => {
    filterState[item] = true
  })
  return filterState
}

export function filterProgramList(programList, filters, sortBy, sortAscending){
  let updatedProgramList = programList.filter(filterByField('deviceTypes', filters))
                                      .filter(filterByField('locations', filters))
                                      .filter(filterByField('sectors', filters))
                                      .filter(filterByField('incentiveTypes', filters))
  
  updatedProgramList.sort((first, second) => {
    if(sortAscending) return first[sortBy] < second[sortBy] ? -1 : 1
    else return first[sortBy] > second[sortBy] ? -1 : 1
  })
  return updatedProgramList
}

function filterByField(field, filters){
  return function(program){
    return program[field].reduce((cumulative, listItem) => {
      return cumulative || filters[field][listItem]
    }, false)
  }
}

export function convertToTitleCase(camelCaseString){
  const result = camelCaseString
    .replace(/([A-Z][a-z])/g, " $1")
    .replace(/([A-Z]+)/g, " $1")
    .replace(/ +/g, " ")
    .replace(/^ +/g, "")

  return result.charAt(0).toUpperCase() + result.slice(1)
}

export function SortIndicator({field, sortBy, sortAscending}){
  if (sortBy === field) {
    if (sortAscending) return <FontAwesomeIcon icon={faCaretDown} />
    else return <FontAwesomeIcon icon={faCaretUp} />
  } else return null

}

export function selectOnlyOne(filters, item){
  let updatedFilters = filters
  Object.keys(filters).forEach(key => {
    (key === item) ? updatedFilters[key] = true : updatedFilters[key] = false
  })
  return updatedFilters
}

export function selectAll(filters){
  let updatedFilters = filters
  Object.keys(filters).forEach(key => {
    updatedFilters[key] = true
  })
  return updatedFilters
}

export function allFiltersOff(filters){
  return Object.keys(filters).reduce((accumulator1, field) => {
    return accumulator1 && Object.keys(filters[field]).reduce((accumulator2, item) => {
      return accumulator2 && filters[field][item]
    }, accumulator1)
  }, true)
}

export function filterStatus(filter){
  const numFieldsTotal = Object.keys(filter).length
  const numFieldsTrue = Object.keys(filter).reduce((accumulator, item) => {
    return accumulator + filter[item]
  }, 0)
  return {
    isActive: numFieldsTotal !== numFieldsTrue,
    numFieldsTrue
  } 
}


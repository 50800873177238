import React, { useState, useEffect, useRef } from "react"
import { Form, Row, Button } from "react-bootstrap"

import {
  convertToTitleCase,
  filterStatus,
} from "../../utils/formatProgramTable"

import styles from './TableFilters.module.css'

const TableFilters = props => {
  
  return (
    <div className={styles.filterContainer}>
      <Row>
        {Object.keys(props.selectedFilters).map((field, item) => (
          <DropdownButton {...props} fieldName={field} key={item} />
        ))}
      </Row>
    </div>
  )
}

const DropdownButton = ({
  selectedFilters,
  updateFilter,
  fieldName,
  selectOnly,
  selectAll,
}) => {
  const [isOpen, toggleOpen] = useState(false)
  const ref = useRef(null)
  const { isActive, numFieldsTrue } = filterStatus(selectedFilters[fieldName])
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      toggleOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [])

  return (
    <div ref={ref}>
      <Button
        variant="outline-secondary"
        className={isActive ? styles.activeDropdownToggle : styles.dropdownToggle}
        onClick={() => toggleOpen(!isOpen)}
      >
        {convertToTitleCase(fieldName)}
        {isActive && (
          <span className={styles.currentStateIndicator}>{numFieldsTrue}</span>
        )}
      </Button>
      {isOpen && (
        <div className={styles.dropdownMenu}>
          {Object.keys(selectedFilters[fieldName]).map((item, index) => (
            <DropdownItem
              selectedFilters={selectedFilters}
              item={item}
              key={index}
              updateFilter={updateFilter}
              fieldName={fieldName}
              selectOnly={selectOnly}
            />
          ))}
          {isActive && (
            <Button
              className={styles.selectAllButton}
              onClick={() => selectAll(fieldName)}
            >
              select all
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

const DropdownItem = ({selectedFilters, fieldName, item, updateFilter, selectOnly}) => {
    const [isShown, setIsShown] = useState(false)
    return (
      <div
        role="none"
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        className={styles.dropdownItem}
      >
        <Form.Check
          type="checkbox"
          id={`check-${item}`}
        >
          <Form.Check.Input
            checked={selectedFilters[fieldName][item]}
            onChange={() => updateFilter(fieldName, item)}
            type="checkbox"
            className={styles.checkboxLabel}
          />
          <Form.Check.Label
            onClick={() => updateFilter(fieldName, item)}
            className={styles.checkboxLabel}
          >
            {item}
          </Form.Check.Label>
          {isShown && (
            <Button
              onClick={() => selectOnly(fieldName, item)}
              className={styles.selectOnly}
              size="sm"
            >
              only
            </Button>
          )}
          {!isShown && <span className={styles.onlyPlaceholder}>only</span>}
        </Form.Check>
      </div>
    )
}

export default TableFilters
